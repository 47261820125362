import React, {ReactElement, useEffect, useState} from 'react';
import {usePageTheme, useApp} from '@Context/AppContext';
import SEO from '@Components/SEO';
import {IPageData, IPageProps} from '@Types/ContentfulTypes';
import ContentLayout from '@Src/components/sitestructure/ContentLayout';
import '@GraphQL/fragments';
import Text, {Heading01} from '@Src/components/Text';
import * as styles from './processing.module.scss';
import Loader from '@Src/components/ui/Loader';
import {makeid} from "@Utils/randomString";
import {graphql} from 'gatsby';

export interface Props extends IPageProps {
  data: {
    contentfulPageTypeSignup: IPageData;
  };
}

const CompletePage = ({location, pageContext, data, transitionStatus}: Props): ReactElement => {
  const {title, seoSettings} = data.contentfulPageTypeSignup;
  const {setTheme: setPageTheme} = usePageTheme();
  const {pageType, setPageType, setPageTitle, mode} = useApp();
  const [localTransStatus, setLocalTransStatus] = useState<string>(transitionStatus);

  useEffect(() => {
    if (pageType === 'index' && transitionStatus == 'exiting') {
      setLocalTransStatus(transitionStatus + 'Index');
    } else {
      setLocalTransStatus(transitionStatus);
    }

    if (transitionStatus == 'entering' || transitionStatus == 'entered') {

      setPageTheme(mode == 'day' ? 'yellow' : 'dark');

      setPageTitle(title);
      setPageType(pageContext.type);
    }
  }, [transitionStatus]);
  useEffect(() => {
    if (transitionStatus == 'entered') {
      setPageTheme(mode == 'yellow' ? mode : mode == 'day' ? 'yellow' : 'dark');
      console.log("mode", mode)
      //setPageTheme(mode == 'day' ? 'yellow' : 'dark');

    }
  }, [mode]);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const session_id = searchParams.get('session_id')
    // @ts-ignore
    if (window.dataLayer) {
      // @ts-ignore
      window.dataLayer.push({
        'event': 'purchase',
        ecommerce: {
          transaction_id: session_id,
          currency: 'EUR',
          description: 'Distribution+',
          value: 250.00,
          tax: 50.00,
          shipping: 0.00,
          quantity: 1,
          content_type: 'product',
          content_id: 1,
          items: [
            {
              item_id: 'Distribution+',
              item_name: 'Distribution+',
              item_brand: 'ALOADED',
              item_category: 'Digital Distribution',
              affiliation: 'ALOADED',
              price: 200.00,
              quantity: 1
            }
          ]
        },
        'eventCallback': function () {
          console.log("Conversion registered")
        },
        'eventTimeout': 2000
      });
    }
  }, [])

  return (
    <ContentLayout transitionState={localTransStatus} fullWidth type={'signup'}>
      <SEO
        location={location.pathname}
        title={seoSettings?.title || title}
        description={seoSettings?.description}
        meta={seoSettings?.metaTags}
        image={seoSettings?.image}
      />
      <div className={styles.flexCenter}>
        <Heading01 theme={mode}>Thanks for your order!</Heading01>
        <Text theme={mode} htmlText={`Your account will be ready soon`}/>
      </div>
    </ContentLayout>
  );
};

export default CompletePage;
export const query = graphql`
	query {
		contentfulPageTypeSignup {
			... on IPageNode {
				...PageFields
			}
		}
	}
`;
